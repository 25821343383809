<template>
  <footer
    id="Trollshaws"
    itemscope
    itemtype="https://schema.org/WPFooter"
  >
    <div class="v-container">
      <div class="v-row v-mb-md">
        <div
          class="v-col-12 v-mb-sm v-about-rest-order"
          :class="[
            appConfig.VueSettingsPreRun.FooterEnableAppQRCode
              ? 'v-col-lg-3'
              : 'v-col-lg-4'
          ]"
        >
          <div
            class="v-text-footer-about-rest"
            v-html="translate('footerPage.restaurantDescription')"
          />
        </div>

        <div
          class="v-col-lg-3 v-col-md-4 v-col-sm-6 v-col-9 v-offset-lg-2 v-payment-card-order"
        >
          <common-payment-cards />
        </div>

        <div
          v-if="appConfig.VueSettingsPreRun.FooterShowApps"
          class="v-col-md-4 v-offset-sm-1 v-offset-md-0 v-col-sm-5 v-apps-order"
          :class="[
            appConfig.VueSettingsPreRun.FooterEnableAppQRCode
              ? 'v-col-lg-4'
              : 'v-col-lg-3'
          ]"
        >
          <div class="v-row v-justify-content-end">
            <div
              v-if="isDesktopVersion"
              :class="{
                'v-col-md-4':
                  appConfig.VueSettingsPreRun.FooterEnableAppQRCode &&
                  !stringIsNullOrWhitespace(
                    appConfig.VueSettingsPreRun.AppDownloadQRBase64
                  )
              }"
            >
              <img
                v-if="
                  appConfig.VueSettingsPreRun.FooterEnableAppQRCode &&
                  !stringIsNullOrWhitespace(
                    appConfig.VueSettingsPreRun.AppDownloadQRBase64
                  )
                "
                class="v-img-fluid"
                :alt="translate('qrPayPopup.overQr')"
                :src="`data:image/png; base64, ${appConfig.VueSettingsPreRun.AppDownloadQRBase64}`"
              />
            </div>
            <div
              :class="{
                'v-col-md-8 col-12': appConfig.VueSettingsPreRun.FooterEnableAppQRCode
              }"
            >
              <common-apps />
            </div>
          </div>
        </div>

        <div
          v-if="appConfig.VueSettingsPreRun.FooterShowApps"
          class="v-col-12 v-line-order"
        >
          <hr class="v-opacity-20" />
        </div>

        <div class="v-col-lg-9 v-col-sm-8 v-col-12 v-links--order">
          <ul class="v-links-footer--list">
            <li
              v-for="(link, index) in externalLinksFooter"
              :key="`v-links-footer-${index}`"
              class="v-links-footer--item v-mb-md"
              :class="{ 'v-mr-sm': !isExtraSmall }"
            >
              <arora-nuxt-link
                class-name="v-link"
                :external-link="!link.Url.startsWith('/')"
                :href="link.Url"
                :label="sanitize(link.Name)"
                :open-in-new-tab="!link.Url.startsWith('/')"
                active-class="v-font-weight-600"
              />
            </li>
            <li
              v-if="appConfig.RestaurantSettingsPreRun.ContentAvailable.HasArticles"
              class="v-links-footer--item v-mb-md"
            >
              <arora-nuxt-link
                class-name="v-link"
                :href="appConfig.VueSettingsPreRun.Links.ArticlesLink"
                :label="translate('footerPage.articles')"
                active-class="v-font-weight-600"
              />
            </li>
            <div
              v-if="isExtraSmall"
              class="v-col-12"
            >
              <hr class="v-opacity-20" />
            </div>
          </ul>
        </div>

        <div class="v-col-lg-2 v-col-sm-4 v-offset-lg-1 v-social-order">
          <div
            v-if="appConfig.VueSettingsPreRun.FooterShowSocial"
            class="v-social-link-footer v-d-flex v-flex-column"
            :class="[isExtraSmall ? 'v-justify-content-start' : 'v-justify-content-end']"
          >
            <common-social-networks
              :text="translate('footerPage.titleSocialNetworksLinks')"
            />
          </div>
          <div
            v-if="isExtraSmall && appConfig.VueSettingsPreRun.FooterShowSocial"
            class="v-col-12"
          >
            <hr class="v-opacity-20" />
          </div>
        </div>
      </div>

      <div class="v-row v-mb-md">
        <div class="v-col-sm-6 v-col-12 v-mb-sm">
          <div class="v-block-legal-professional">
            <div
              v-if="appConfig.VueSettingsPreRun.FooterShowCopyright"
              class="v-copyright v-mr-md"
            >
              <meta
                :content="`${currentYear}`"
                itemprop="copyrightYear"
              />
              <meta
                :content="appConfig.VueSettingsPreRun.CopyRightOrgName"
                itemprop="copyrightHolder"
              />
              <span v-html="copyrightYears" />
            </div>
            <div class="v-privacy-politic">
              <arora-nuxt-link
                class-name="v-link"
                :href="appConfig.VueSettingsPreRun.Links.AgreementLink"
                :label="translate('footerPage.privacy')"
                active-class="v-active"
              />
            </div>
          </div>
        </div>
        <div
          class="v-col-sm-6 v-col-12 v-mb-sm v-holder-order"
          :class="{
            'v-mb-sm': isExtraSmall
          }"
        >
          <div
            v-if="appConfig.VueSettingsPreRun.FooterShowLegalLogo"
            class="v-copyright-holder"
          >
            <arora-nuxt-link
              class-name="v-fz-0"
              open-in-new-tab
              :href="linkSite"
              :label="linkSite"
            >
              <icon-old-footer-arora />
            </arora-nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import type { FooterCommon } from '~types/props'

import { useCommon, useWindowSize } from '@arora/common'

defineProps<FooterCommon>()

const appConfig = useAppConfig()
const { stringIsNullOrWhitespace } = useCommon()
const { sanitize, translate } = useI18nSanitized()
const { isDesktopVersion, isExtraSmall } = useWindowSize()
const currentYear = new Date().getFullYear()
</script>

<style lang="scss">
@use 'assets/mixins';

#Trollshaws {
  .v-about-rest-order {
    order: 1;
    .v-text-footer-about-rest {
      margin-bottom: 16px;
    }
    @include mixins.sm {
      order: 1;

      .v-text-footer-about-rest {
        margin-bottom: 0;
      }
    }
  }
  .v-payment-card-order {
    order: 2;
    @include mixins.sm {
      order: 4;
    }
  }
  .v-apps-order {
    order: 3;
    @include mixins.sm {
      order: 6;
    }
  }
  .v-line-order {
    order: 4;
    @include mixins.sm {
      order: 2;
    }
  }
  .v-links--order {
    order: 5;

    .v-links-footer {
      &--list {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
      &--item {
        .v-link {
          opacity: 0.4;
          font-size: 1rem;
        }
        .v-link {
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
    @include mixins.sm {
      order: 3;
      padding-bottom: 4px;
      .v-links-footer {
        &--list {
          flex-direction: column;
        }
      }
    }
  }

  .v-social-order {
    order: 6;
    .v-social-text-about {
      justify-content: flex-end;
    }
    .v-social-link-footer {
      flex-wrap: wrap;
    }
    @include mixins.sm {
      order: 5;

      .v-social-text-about {
        justify-content: flex-start;
      }
      .v-social-link-footer {
        flex-direction: column;
        .v-social-networks {
          flex-direction: row;
        }
      }
    }
  }
}
</style>
